import NavBar from "../navbar/navbar.component";

const events = [
    {
        name: "COMPANIONS",
        date: "JAN 13 2022",
        dull: false
    },
    {
        name: "MERCHANDISE OPEN",
        date: "Q2 2022",
        dull: false
    },
    {
        name: "ENEMIES",
        date: "Q1-Q2 2022",
        dull: false
    },
    {
        name: "METAVERSE ITEMS",
        date: "Q2-Q3 2022",
        dull: true
    },
    {
        name: "METAVERSE",
        date: "Q2-Q3 2022",
        dull: false
    },
    {
        name: "GAME IN METAVERSE",
        date: "2023",
        dull: false
    }
]

const Roadmap = () => {
    return (
        <div>
            <div className="px-10 sm:px-16 md:px-20 xl:px-16">
                <NavBar />
            </div>
            <div className="pt-20 px-10 sm:px-16 md:px-20 xl:px-16">
                <header className="text-white">
                    <h1 className="usc-heavy text-6xl text-center">ROADMAP</h1>
                </header>

                <div className="px-10 sm:px-20 md:px-32 lg:px-52 xl:px-56 mt-16 pb-10 mx-auto">
                    <div className="">
                        {
                            events.map((event, i) => {
                                return (
                                    <div key={i} className="flex flex-col sm:flex-row pb-5">
                                        <div className={`${event.dull ? "text-opacity-50" : ""} text-white usc-bold text-3xl sm:text-4xl lg:text-5xl mr-5`}>{event.name}</div>
                                        {
                                            event.date && <div className={`${event.dull ? "opacity-50p" : ""} bby-blue usc-light text-3xl sm:text-4xl lg:text-5xl`}>{event.date}</div>
                                        }
                                    </div>                                
                                )
                            })
                        }

                    </div>
                </div>
            </div>
            <div className="pb-20">
                <video className="" autoPlay loop muted playsInline>
                    <source src="https://storage.googleapis.com/projecturs-assets/urs_roadmap.mp4" type="video/mp4" />
                </video>
            </div>

        </div>
    )
}

export default Roadmap;