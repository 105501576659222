import { Link, useNavigate } from "react-router-dom";

import URSLogo from "../assets/urs-logo/urs-logo.component";

import tiktokIcon from "../../assets/social-icons/tiktok.png";
import instagramIcon from "../../assets/social-icons/instagram.png";
import discordIcon from "../../assets/social-icons/discord.png";
import twitterIcon from "../../assets/social-icons/twitter.png";
import openseaIcon from "../../assets/social-icons/opensea.png";

const nav = [
    {
        name: "PROJECTS",
        to: "/projects"
    },
    {
        name: "ROADMAP",
        to: "/roadmap"
    },
    {
        name: "ABOUT",
        to: "/about"
    }
]



const NavBar = () => {
    const navigate = useNavigate();

    return (
        <div className="flex flex-col md:flex-row usc-bold text-white justify-between items-center pt-12 md:px-10 lg:px-8 xl:px-6 md:px md:text-xl lg:text-2xl xl:text-3xl">
            <div className="urs-logo hover:cursor-pointer" onClick={() => navigate("/")}>
                <URSLogo fill="white" width="100" />
            </div>
            <nav className="flex justify-around w-full px-4 text-2xl mt-5 md:mt-0">
                { nav.map(n => {
                    return (
                        <Link key={n.name} to={n.to}>
                            {n.name}
                        </Link>
                    )
                })}
            </nav>
            <div className="flex justify-between items-center w-64 mt-5 md:mt-0">
                <a className="mr-3" href="https://www.tiktok.com/@urs_project" target="_blank" rel="noreferrer"><img style={{filter: "brightness(0) invert(1)"}} width="30" src={tiktokIcon} alt="Tiktok Link" /></a>
                <a className="mr-3" href="https://www.instagram.com/urs_project/" target="_blank" rel="noreferrer"><img style={{filter: "brightness(0) invert(1)"}} width="30" src={instagramIcon} alt="Instagram Link" /></a>
                <a className="mr-3" href="https://discord.gg/vutKFPaUXU" target="_blank" rel="noreferrer"><img style={{filter: "brightness(0) invert(1)"}} width="30" src={discordIcon} alt="Discord Link" /></a>
                <a className="mr-3" href="https://twitter.com/TheProjectURS" target="_blank" rel="noreferrer"><img style={{filter: "brightness(0) invert(1)"}} width="30" src={twitterIcon} alt="Twitter Link" /></a>
                <a href="https://opensea.io/collection/urscompanions" target="_blank" rel="noreferrer"><img style={{filter: "brightness(0) invert(1)"}} width="30" src={openseaIcon} alt="Opensea Link" /></a>
            </div>
        </div>
    )
}

export default NavBar;