import NavBar from "../navbar/navbar.component";
import faq from "../../utils/faq.json";

const team = [
    {
        name: "EMMA",
        image: "https://storage.googleapis.com/projecturs-assets/team-pics/team4.jpg"
    },
    {
        name: "EVILLADY",
        image: "https://storage.googleapis.com/projecturs-assets/team-pics/team2.jpg"
    },
    {
        name: "JUNE_REDTHREAD",
        image: "https://storage.googleapis.com/projecturs-assets/team-pics/team6.jpg"
    },
    {
        name: "TOMAYTRAVEL",
        image: "https://storage.googleapis.com/projecturs-assets/team-pics/team3.jpg"
    },
    {
        name: "BII_COM",
        image: "https://storage.googleapis.com/projecturs-assets/team-pics/team1.jpg"
    },
    {
        name: "CKHMOD",
        image: "https://storage.googleapis.com/projecturs-assets/team-pics/team9.jpg"
    },
    {
        name: "HWALE",
        image: "https://storage.googleapis.com/projecturs-assets/team-pics/team8.jpg"
    },
    {
        name: "LEE NAM GOOK",
        image: "https://storage.googleapis.com/projecturs-assets/team-pics/team7.jpg"
    },
    {
        name: "D",
        image: "https://storage.googleapis.com/projecturs-assets/team-pics/team10.jpg"
    } 
]

const TeamProfile = ({ name, image }) => {
    return (
        <div className="mb-7">
            <img className="mb-5" style={{maxWidth: "none"}} width="300" src={image} alt="Team Pic" />
            <div className="usr-heavy text-white text-xl text-center">{name}</div>
        </div>
    )
}

const Faq = ({ question, answer }) => {
    return (
        <div className="mb-8">
            <div className="text-white mb-2">{question}</div>
            <div className="bby-blue">{answer}</div>
        </div>
    )
}

const About = () => {
    return (
        <div>
            <div>
                <div className="px-10 sm:px-16 md:px-20 xl:px-16">
                    <NavBar />
                </div>
                <div className="text-white mt-28 px-10 sm:px-16 md:px-20 xl:px-16">
                    <div className="usc-bold text-4xl tracking-wider"><p className="usc-heavy inline">OUR MISSION</p> IS TO INSPIRE OUR COMMUNITY WITH <p className="bby-blue inline">CURIOSITY</p>, <p className="bby-blue inline">AN ADVENTUROUS SPIRIT</p>, AND <p className="bby-blue inline">AFFECTION</p> THROUGH THE EXPERIENCE OF URS AND COMPANIONS. </div>
                    <div className="usc-bold text-4xl tracking-wider">WITHIN <p className="bby-blue inline">THE METAVERSE</p>, WE STRIVE TO CONNECT PEOPLE INTO A COMMUNITY THROUGH <p className="bby-blue inline">ENTERTAINMENT</p> AND <p className="bby-blue inline">FULFILLMENT</p>.</div>
                </div>
                <div className="text-white mt-16 px-10 sm:px-16 md:px-20 xl:px-16">
                    <div className="usc-heavy text-4xl">URS COMPANION TEAM</div>
                </div>
                <div className="mt-12">

                    <div className="flex flex-wrap justify-around">
                        {
                            team.map(mem => <TeamProfile key={mem.name} name={mem.name} image={mem.image} />)
                        }
                    </div>
                </div>



                <div className="pb-20 pt-5 px-10 sm:px-16 md:px-20 xl:px-16">
                    <div className="usc-heavy text-white text-6xl mt-16 mb-12">Q & A</div>
                    <div className="usr-medium text-xl">
                        {
                            faq.map((one, i) => <Faq key={i} question={one.question} answer={one.answer} />)
                        }
                    </div>
                    <p className="text-white usr-medium text-xl">Cofounders Link: </p><a className="bby-blue usr-medium text-xl" href="https://www.theprojecturs.com/">https://www.theprojecturs.com/</a>
                </div>
            </div>
        </div>
    )
}

export default About;