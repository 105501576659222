import { Routes, Route, useLocation } from "react-router-dom";

import NavBar from "./components/navbar/navbar.component";
import Landing from "./components/landing/landing.component";
import Projects from "./components/projects/projects.component";
import Roadmap from "./components/roadmap/roadmap.component";
import About from "./components/about/about.component";

const paths = {
  "/": "full",
  "/companion-claim": "full",
  "/claim-check": "full",
  "/projects": "scroll",
  "/roadmap": "scroll",
  "/about": "scroll"
}

function App() {
  const location = useLocation();
  const { pathname } = location;
  const pathType = paths[pathname];

  return (
    <div 
      className={`${pathType === "scroll" ? "bg-black": ""}`}
      style={
        pathname === "/roadmap"
          ? { backgroundColor: "#010619"}
          : null
      }
    >
      <div className={`main-container mx-auto min-h-screen`}>
        {
          pathType === "full" && <NavBar />
        }        
        <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/roadmap" element={<Roadmap /> } />
            <Route path="/about" element={<About />} />
        </Routes>

      </div>
    </div>
  );
}

export default App;
