import NavBar from "../navbar/navbar.component";
import projectBg1 from "../../assets/backgrounds/project-bg-1.jpg";
import projectBg2 from "../../assets/backgrounds/project-bg-2.jpg";
import projectBg6 from "../../assets/backgrounds/project-bg-6.jpg";

import "./projects.style.css";

const Projects = () => {
    return (
        <div>
            <div style={{
                background: `url("${projectBg1}") no-repeat`,
                backgroundSize: "cover"
            }}>
                <div className="px-10 sm:px-16 md:px-20 xl:px-16">
                    <NavBar />
                </div>
                <div className="mt-24 pb-20 px-10 sm:px-16 md:px-20 xl:px-16 text-white">
                    <div className="flex items-center">
                        <div className="usc-heavy text-7xl">URS</div><div className="usc-medium ml-5 text-2xl text-wider">(UNDERGROUND ROBOGENETIC SURVIVALIST)</div>                        
                    </div>
                    <div className="usr-medium mt-8 text-2xl">
                        <div>
                            The URS Project is a revolutionary 3d animated collection of 10K avatars that live on the <p className="bby-blue inline">Ethereum blockchain</p><p className="inline">, created by a team of Korean artists. </p>

                        </div>
                        <div className="mt-7">
                            In 2130, while humanity was forced underground, URS (Underground Robogenetic Survivalists) were created <p className="bby-blue inline">to access the warp gates</p> which connected to the earth’s scorched surface and gather essential supplies. 

                        </div>
                        <div className="mt-7">
                            Each URS is a high quality 3d animated video while also serving as an <p className="bby-blue inline">entry into a community and roadmap</p> that includes free mints for holders, merchandise, metaverse items and games..
                             
                        </div>
                    </div>
                </div>

            </div>
            <div style={{
                background: `url("${projectBg2}") no-repeat center`,
                backgroundSize: "1080px",
                height: "600px"
            }} />

            <div className="pb-12 pt-20 px-10 sm:px-16 md:px-20 xl:px-16">
                <div className="usc-heavy text-white text-7xl">
                    COMPANIONS
                </div>
                <div className="usr-medium">
                    <div className="pt-5">

                        <div className="text-white text-2xl">
                            Each URS Companion help URS to survive through its own unique function. 
                            One URS holder can claim One URS Companion. Depends on <p className="bby-blue inline">URS skin types</p>, 
                            the holders get a different type of companions. The companions are set to 
                            release <p className="bby-blue inline">in January</p> this year.
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col md:block">
                <div
                    className="flex flex-col md:flex-row pb-16 px-10 sm:px-16 md:px-20 xl:px-16 pb-3 md:pb-10"
                >
                    <div
                        className="companion-showcase__image bg3 order-2 md:order-1"
                    >
                        <video style={{height: "100%"}} height="100%" width="100%" className="object-contain" autoPlay loop muted playsInline>
                            <source src="https://storage.googleapis.com/projecturs-assets/hoverbowl.mp4" type="video/mp4" />
                        </video>
                    </div>
                    <div className="companion-showcase__text pb-5 md:pb-0 md:pb-0 order-1 md:order-2 flex flex-col justify-center">
                        <div className="bby-blue flex flex-col justify-center text-center">
                            <div className="usc-light text-7xl lg:text-8xl">HOVER BOWL</div>
                            <div className="usr-heavy slime-green text-2xl">#0005</div>
                            <div className="usr-heavy text-lg lg:text-xl pt-3 px-3">
                                    A COMBAT COMPANION,CAPABLE OF AERIAL 
                                    RECONNAISSANCE AND PRECISE 
                                    INTERCEPTION TACTICS.
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="flex flex-col md:flex-row pb-16 px-10 sm:px-16 md:px-20 xl:px-16 pb-3 md:pb-10"
                >
                    <div className="companion-showcase__text pb-5 md:pb-0 md:pb-0 flex flex-col justify-center">
                        <div className="bby-blue flex flex-col justify-center text-center">
                            <div className="usc-light text-7xl lg:text-8xl">BOB CARRIER</div>
                            <div className="usr-heavy slime-green text-2xl">#2375</div>
                            <div className="usr-heavy text-lg lg:text-xl pt-3 px-3">
                                A COMMANDER COMPANION, A MOBILE 
                                BARRACK THAT RECEIVES SUPPLIES 
                                AND TELEPORTS TO THE UNDERWORLD 
                                THROUGH A SMALL PORTAL INSIDE.
                            </div>
                        </div>
                    </div>
                    <div
                        className="companion-showcase__image bg4"
                    >
                        <video style={{height: "100%"}} height="100%" width="100%" className="object-contain" autoPlay loop muted playsInline>
                            <source src="https://storage.googleapis.com/projecturs-assets/bobcarrier.mp4" type="video/mp4" />
                        </video>
                    </div>
                </div>

                <div
                    className="flex flex-col md:flex-row px-10 sm:px-16 md:px-20 xl:px-16 pb-3 md:pb-10"
                >
                    <div className="companion-showcase__text pb-5 md:pb-0 md:pb-0 flex flex-col justify-center">
                        <div className="bby-blue flex flex-col justify-center text-center">
                            <div className="usc-light text-7xl lg:text-8xl">META PUP</div>
                            <div className="usr-heavy slime-green text-2xl">#0067</div>
                            <div className="usr-heavy text-lg lg:text-xl pt-3 px-3">
                                URS’S BEST FRIEND, HELPS EMOTIONAL 
                                STABILITY AND INCREASES CAPACITY
                                FOR ACTION. MAIN ABILITY: GROUND MISSIONS.
                            </div>
                        </div>
                    </div>
                    <div
                        className="companion-showcase__image bg5"
                    >
                        <video style={{height: "100%"}} height="100%" width="100%" className="object-contain" autoPlay loop muted playsInline>
                            <source src="https://storage.googleapis.com/projecturs-assets/metapup.mp4" type="video/mp4" />
                        </video>
                    </div>
                </div>
            </div>

            <div
                className="mt-16 px-10 sm:px-16 md:px-20 xl:px-16 pb-10"
                style={{
                    color: "#F5F2E5"
                }}
            >
                <div className="usc-heavy text-6xl">
                    STORY
                </div>
                <div 
                    className="usr-medium text-xl mt-5"
                > 
                <div className="mb-5">In the year 2130, humanity struggled to rebuild after World Wars IV and V while enduring radiation storms, famines, droughts, and a devastated atmosphere. They were toughened by the struggle, and, for a time, they survived. But the cities never stopped burning and the surface died. Years went by and a new era of humanity began underground but needed a miracle to sustain itself. That miracle came in the form of an accidental discovery of ancient warp gates that transported to the surface, allowing for scarce resources they needed to survive another week, month, and year.</div>    
                <div className="mb-5">Scientists sent prototype machines with human DNA through the gateways to go to the surface. Designated as Underground Robogenetic Survivalists, URS for short, they became the forerunners for exploring the surface and bringing back resources. Humanity once again had a chance.  </div>
                <div className="mb-5">Eventually the URS robots seemed to become self-aware. They returned through the gates with simulated feelings, emotions, and attitudes and behaved like real people. And they shared the same goal as their human creators: find a new home for humans and bots alike. Are you ready to jump through the warpgate with them to find it?</div>
                </div>
            </div>

            <div
                style={{
                    height: "600px",
                    background: `url("${projectBg6}") no-repeat center`,
                    backgroundSize: "cover"
                }}
            ></div>

            <div className="flex justify-center py-20 usr-medium text-white text-sm">
                <div>©2022 URS PROJECT.</div>
            </div>
        </div>
    )
}

export default Projects;