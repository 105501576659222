import Countdown from "react-countdown";

import landingCompanion from "../../assets/landing-companion.mp4";

const NumberComponent = ({ number }) => <div className="usc-heavy text-6xl sm:text-8xl lg:text-9xl">{number}</div>;
const Descriptor = ({ name }) => <div className="usr-medium">{name}</div>;
const TimePiece = ({ number, name }) => {
    return (
        <div className="flex flex-col items-center">
            <NumberComponent number={number} />
            <Descriptor name={name}/>
        </div>
    )
};
const ColonSeparator = () => <div className="usc-heavy pb-12 lg:pb-16 text-6xl sm:text-8xl lg:text-9xl">:</div>;

const timeRenderer = ({ days, hours, minutes, seconds }) => {
    return (
        <div className="flex justify-between items-center px-3 lg:px-16">
            <TimePiece number={`0${days}`.slice(-2)} name="DAYS" />
            <ColonSeparator />
            <TimePiece number={`0${hours}`.slice(-2)} name="HOURS" />
            <ColonSeparator />
            <TimePiece number={`0${minutes}`.slice(-2)} name="MINUTES" />
            <ColonSeparator />
            <TimePiece number={`0${seconds}`.slice(-2)} name="SECONDS" />
        </div>
    )
}

const PreLaunch = ({ date }) => {
    return (
        <div className="relative">
            <video style={{zIndex: "-1"}} className="w-screen h-screen object-cover fixed left-0 right-0 top-0 bottom-0" autoPlay loop muted playsInline>
                <source src={landingCompanion} type="video/mp4" />
            </video>
            <div className="landing-container">
                <div className="px-6 sm:px-24">
                    <div style={{color: "#e7e5cd"}}>
                        <div className="flex flex-col justify-between">
                            <div className="mx-auto text-lg">TIME REMAINING</div>
                            <Countdown date={date} renderer={timeRenderer} />                                    
                            <div className="go-live usc-heavy flex flex-col md:flex-row justify-around md:justify-center lg:justify-between mt-1 mt-6 text-4xl lg:text-6xl">                                
                                <div className="mx-auto"><p style={{
                                    color:"#72c0e4",
                                }} className="inline mr-2">>></p>COMPANIONS GO LIVE!</div>
                                <div style={{
                                    backgroundColor:"#e7e5cd",
                                }} >
                                    <div className="drop-time text-black text-center px-3">01.13.2022<p className="usc-bold inline">(PST)</p></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex usc-bold text-2xl sm:text-3xl lg:text-4xl mt-10 sm:mt-12" style={{color:"#72c0e4"}}>
                        <div className="mx-auto">
                            NEW OFFICIAL WEBSITE COMING SOON
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PreLaunch;